exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-old-js": () => import("./../../../src/pages/about-old.js" /* webpackChunkName: "component---src-pages-about-old-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-b-2-b-website-design-agency-old-js": () => import("./../../../src/pages/b2b-website-design-agency-old.js" /* webpackChunkName: "component---src-pages-b-2-b-website-design-agency-old-js" */),
  "component---src-pages-b-2-b-website-design-agency-tsx": () => import("./../../../src/pages/b2b-website-design-agency.tsx" /* webpackChunkName: "component---src-pages-b-2-b-website-design-agency-tsx" */),
  "component---src-pages-b-2-c-web-design-agency-old-js": () => import("./../../../src/pages/b2c-web-design-agency-old.js" /* webpackChunkName: "component---src-pages-b-2-c-web-design-agency-old-js" */),
  "component---src-pages-b-2-c-web-design-agency-tsx": () => import("./../../../src/pages/b2c-web-design-agency.tsx" /* webpackChunkName: "component---src-pages-b-2-c-web-design-agency-tsx" */),
  "component---src-pages-brand-identity-design-services-old-js": () => import("./../../../src/pages/brand-identity-design-services-old.js" /* webpackChunkName: "component---src-pages-brand-identity-design-services-old-js" */),
  "component---src-pages-brand-identity-design-services-tsx": () => import("./../../../src/pages/brand-identity-design-services.tsx" /* webpackChunkName: "component---src-pages-brand-identity-design-services-tsx" */),
  "component---src-pages-case-study-dashboard-ui-ux-design-js": () => import("./../../../src/pages/case-study/dashboard-ui-ux-design.js" /* webpackChunkName: "component---src-pages-case-study-dashboard-ui-ux-design-js" */),
  "component---src-pages-case-study-ecommerce-fashion-website-design-js": () => import("./../../../src/pages/case-study/ecommerce-fashion-website-design.js" /* webpackChunkName: "component---src-pages-case-study-ecommerce-fashion-website-design-js" */),
  "component---src-pages-case-study-library-mobile-app-design-js": () => import("./../../../src/pages/case-study/library-mobile-app-design.js" /* webpackChunkName: "component---src-pages-case-study-library-mobile-app-design-js" */),
  "component---src-pages-case-study-nft-marketplace-ui-design-js": () => import("./../../../src/pages/case-study/nft-marketplace-ui-design.js" /* webpackChunkName: "component---src-pages-case-study-nft-marketplace-ui-design-js" */),
  "component---src-pages-case-study-saas-app-design-js": () => import("./../../../src/pages/case-study/saas-app-design.js" /* webpackChunkName: "component---src-pages-case-study-saas-app-design-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-custom-shopify-website-design-old-js": () => import("./../../../src/pages/custom-shopify-website-design-old.js" /* webpackChunkName: "component---src-pages-custom-shopify-website-design-old-js" */),
  "component---src-pages-custom-shopify-website-design-tsx": () => import("./../../../src/pages/custom-shopify-website-design.tsx" /* webpackChunkName: "component---src-pages-custom-shopify-website-design-tsx" */),
  "component---src-pages-custom-website-design-development-tsx": () => import("./../../../src/pages/custom-website-design-development.tsx" /* webpackChunkName: "component---src-pages-custom-website-design-development-tsx" */),
  "component---src-pages-custom-wordpress-website-design-services-old-js": () => import("./../../../src/pages/custom-wordpress-website-design-services-old.js" /* webpackChunkName: "component---src-pages-custom-wordpress-website-design-services-old-js" */),
  "component---src-pages-custom-wordpress-website-design-services-tsx": () => import("./../../../src/pages/custom-wordpress-website-design-services.tsx" /* webpackChunkName: "component---src-pages-custom-wordpress-website-design-services-tsx" */),
  "component---src-pages-ecommerce-website-design-services-tsx": () => import("./../../../src/pages/ecommerce-website-design-services.tsx" /* webpackChunkName: "component---src-pages-ecommerce-website-design-services-tsx" */),
  "component---src-pages-ecommerce-website-redesign-services-js": () => import("./../../../src/pages/ecommerce-website-redesign-services.js" /* webpackChunkName: "component---src-pages-ecommerce-website-redesign-services-js" */),
  "component---src-pages-enterprise-ui-ux-design-js": () => import("./../../../src/pages/enterprise-ui-ux-design.js" /* webpackChunkName: "component---src-pages-enterprise-ui-ux-design-js" */),
  "component---src-pages-enterprise-ui-ux-design-v-2-tsx": () => import("./../../../src/pages/enterprise-ui-ux-design-v2.tsx" /* webpackChunkName: "component---src-pages-enterprise-ui-ux-design-v-2-tsx" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kolkata-index-tsx": () => import("./../../../src/pages/kolkata/index.tsx" /* webpackChunkName: "component---src-pages-kolkata-index-tsx" */),
  "component---src-pages-mobile-app-ui-design-services-tsx": () => import("./../../../src/pages/mobile-app-ui-design-services.tsx" /* webpackChunkName: "component---src-pages-mobile-app-ui-design-services-tsx" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-process-js": () => import("./../../../src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-responsive-web-design-services-tsx": () => import("./../../../src/pages/responsive-web-design-services.tsx" /* webpackChunkName: "component---src-pages-responsive-web-design-services-tsx" */),
  "component---src-pages-saas-website-design-agency-tsx": () => import("./../../../src/pages/saas-website-design-agency.tsx" /* webpackChunkName: "component---src-pages-saas-website-design-agency-tsx" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thank-you-blog-details-js": () => import("./../../../src/pages/thank-you-blog-details.js" /* webpackChunkName: "component---src-pages-thank-you-blog-details-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-thank-you-service-js": () => import("./../../../src/pages/thank-you-service.js" /* webpackChunkName: "component---src-pages-thank-you-service-js" */),
  "component---src-pages-ui-ux-design-case-study-old-js": () => import("./../../../src/pages/ui-ux-design-case-study-old.js" /* webpackChunkName: "component---src-pages-ui-ux-design-case-study-old-js" */),
  "component---src-pages-ui-ux-design-case-study-tsx": () => import("./../../../src/pages/ui-ux-design-case-study.tsx" /* webpackChunkName: "component---src-pages-ui-ux-design-case-study-tsx" */),
  "component---src-pages-ui-ux-design-kolkata-js": () => import("./../../../src/pages/ui-ux-design-kolkata.js" /* webpackChunkName: "component---src-pages-ui-ux-design-kolkata-js" */),
  "component---src-pages-ui-ux-design-new-york-tsx": () => import("./../../../src/pages/ui-ux-design-new-york.tsx" /* webpackChunkName: "component---src-pages-ui-ux-design-new-york-tsx" */),
  "component---src-pages-ui-ux-design-services-startup-old-js": () => import("./../../../src/pages/ui-ux-design-services-startup-old.js" /* webpackChunkName: "component---src-pages-ui-ux-design-services-startup-old-js" */),
  "component---src-pages-ui-ux-design-services-startup-tsx": () => import("./../../../src/pages/ui-ux-design-services-startup.tsx" /* webpackChunkName: "component---src-pages-ui-ux-design-services-startup-tsx" */),
  "component---src-pages-ui-ux-development-service-old-js": () => import("./../../../src/pages/ui-ux-development-service-old.js" /* webpackChunkName: "component---src-pages-ui-ux-development-service-old-js" */),
  "component---src-pages-ui-ux-development-service-tsx": () => import("./../../../src/pages/ui-ux-development-service.tsx" /* webpackChunkName: "component---src-pages-ui-ux-development-service-tsx" */),
  "component---src-pages-ux-audit-services-old-js": () => import("./../../../src/pages/ux-audit-services-old.js" /* webpackChunkName: "component---src-pages-ux-audit-services-old-js" */),
  "component---src-pages-ux-audit-services-tsx": () => import("./../../../src/pages/ux-audit-services.tsx" /* webpackChunkName: "component---src-pages-ux-audit-services-tsx" */),
  "component---src-pages-ux-design-agency-js": () => import("./../../../src/pages/ux-design-agency.js" /* webpackChunkName: "component---src-pages-ux-design-agency-js" */),
  "component---src-pages-ux-research-services-old-js": () => import("./../../../src/pages/ux-research-services-old.js" /* webpackChunkName: "component---src-pages-ux-research-services-old-js" */),
  "component---src-pages-ux-research-services-tsx": () => import("./../../../src/pages/ux-research-services.tsx" /* webpackChunkName: "component---src-pages-ux-research-services-tsx" */),
  "component---src-pages-ux-ui-design-and-consulting-services-tsx": () => import("./../../../src/pages/ux-ui-design-and-consulting-services.tsx" /* webpackChunkName: "component---src-pages-ux-ui-design-and-consulting-services-tsx" */),
  "component---src-pages-web-app-design-services-tsx": () => import("./../../../src/pages/web-app-design-services.tsx" /* webpackChunkName: "component---src-pages-web-app-design-services-tsx" */),
  "component---src-pages-web-design-agency-js": () => import("./../../../src/pages/web-design-agency.js" /* webpackChunkName: "component---src-pages-web-design-agency-js" */),
  "component---src-pages-webflow-web-design-agency-old-js": () => import("./../../../src/pages/webflow-web-design-agency-old.js" /* webpackChunkName: "component---src-pages-webflow-web-design-agency-old-js" */),
  "component---src-pages-webflow-web-design-agency-tsx": () => import("./../../../src/pages/webflow-web-design-agency.tsx" /* webpackChunkName: "component---src-pages-webflow-web-design-agency-tsx" */),
  "component---src-pages-webflow-web-design-agency-v-2-tsx": () => import("./../../../src/pages/webflow-web-design-agency-v2.tsx" /* webpackChunkName: "component---src-pages-webflow-web-design-agency-v-2-tsx" */),
  "component---src-pages-website-redesign-services-tsx": () => import("./../../../src/pages/website-redesign-services.tsx" /* webpackChunkName: "component---src-pages-website-redesign-services-tsx" */),
  "component---src-pages-woocommerce-website-design-services-old-js": () => import("./../../../src/pages/woocommerce-website-design-services-old.js" /* webpackChunkName: "component---src-pages-woocommerce-website-design-services-old-js" */),
  "component---src-pages-woocommerce-website-design-services-tsx": () => import("./../../../src/pages/woocommerce-website-design-services.tsx" /* webpackChunkName: "component---src-pages-woocommerce-website-design-services-tsx" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/BlogList.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-single-blog-js": () => import("./../../../src/templates/SingleBlog.js" /* webpackChunkName: "component---src-templates-single-blog-js" */)
}

